// Text banner

document.addEventListener('DOMContentLoaded', () => {
    const HMTLslides = document.querySelectorAll('.slide')
    const slides = [...HMTLslides]

    function isShow(element) {
        return element.classList.contains('active-text')
    }

    function switchClass(elements, className = '') {
        for (let i = 0; i < elements.length; i++) {

            if (isShow(elements[i])) {
                elements[i].classList.toggle(className)
                if (elements[i + 1] === undefined) {
                    elements[0].classList.toggle(className)
                } else {
                    elements[i + 1].classList.toggle(className)
                }
                break
            }
        }
    }

    const interval = setInterval(() => {
        switchClass(slides, 'active-text')
    }, 3000);

})

// move players 

!(function (e) {
    var o = e(".football"),
        t = e(".basketball"),
        c = e(".soccer");
    e(window).on("mousemove", function (e) {
        var a = -e.pageX / 50 + "px",
            s = e.pageX / 50 + "px",
            n = -e.pageX / 30 + "px";
        o.css("margin-left", n), t.css("margin-left", a), c.css("margin-left", s);
    });
})(jQuery);

// modal

document.addEventListener('DOMContentLoaded', () => {
    const btn = document.querySelector('.main-btn')
    const modal = document.querySelector('.register')
    modal.dataset.hidden = 'true'

    function openModal(modal) {
        modal.style.opacity = '1'
        modal.style.zIndex = '100'
        modal.dataset.hidden = 'false'
    }

    function closeModal(modal) {
        modal.style.opacity = '0'
        modal.style.zIndex = '-1'
        modal.dataset.hidden = 'true'
    }


    btn.addEventListener('click', (e) => {
        e.preventDefault()
        if (modal.dataset.hidden === 'true') {
            openModal(modal)
        } else {
            closeModal(modal)
        }
    })

    modal.addEventListener('click', (e) => {
        if (modal.dataset.hidden === 'false' && e.target.classList.contains('register')) {
            closeModal(modal)
        }
    })
})